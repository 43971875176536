import { components, GroupBase, SelectComponentsConfig } from "react-select";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { Link } from "../link";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import { CustomCheckbox } from "../custom-checkbox";
import { Box } from "../box";
import {
  MultiDeleteItemBtnProps,
  MultiMenuBtnProps,
  MultiSelectBtnProps,
  MultiSelectComponentsType,
  OptionType,
} from "./types";
import { CryptoIcon } from "../crypto-icon";

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <CustomIcon icon="chevron-down" />
  </components.DropdownIndicator>
);

const Control = (props: any) => {
  // eslint-disable-next-line
  const { children } = props;
  return (
    <components.Control className="rs-control" {...props}>
      {children}
    </components.Control>
  );
};

const ConditionalLinkWrapper = ({ link, children }: { link?: string; children: JSX.Element }) =>
  link ? (
    <Link component={RouterLink} to={link} color="inherit" underline="none">
      {children}
    </Link>
  ) : (
    children
  );

const Option = (props: any) => {
  const {
    data: { link, customIcon, cryptoIcon, label, tag, imgSrc },
  } = props as { data: OptionType };

  return (
    <ConditionalLinkWrapper link={link}>
      <components.Option {...props}>
        {customIcon && <CustomIcon icon={customIcon} className="rs-icon" />}
        {cryptoIcon && <CryptoIcon icon={cryptoIcon} className="rs-icon" />}
        {imgSrc && <img src={imgSrc} alt={imgSrc} width={24} height={24} />}
        <span>{label}</span>
        {tag && (
          <Typography color="white.opacity80" fontSize={12}>
            {tag}
          </Typography>
        )}
      </components.Option>
    </ConditionalLinkWrapper>
  );
};
const SingleValue = (props: any): any => {
  const {
    data: { customIcon, cryptoIcon, label, imgSrc },
  } = props as { data: OptionType };

  return (
    <components.SingleValue {...props}>
      {customIcon && <CustomIcon icon={customIcon} className="rs-icon" />}
      {cryptoIcon && <CryptoIcon icon={cryptoIcon} className="rs-icon" />}
      {imgSrc && <img src={imgSrc} alt={imgSrc} width={24} height={24} />}
      {/* eslint-disable-next-line */}
      <span>{label}</span>
    </components.SingleValue>
  );
};

const MultiValue = (props: any): any => {
  const {
    data: { customIcon, cryptoIcon, label, tag },
  } = props as { data: OptionType };

  return (
    <components.MultiValue {...props}>
      <Box display="flex" gap={1}>
        {customIcon && <CustomIcon icon={customIcon} className="rs-icon" />}
        {cryptoIcon && <CryptoIcon icon={cryptoIcon} className="rs-icon" size="small" />}
        <span>{label}</span>
        {tag && (
          <Typography color="white.opacity80" fontSize={12}>
            {tag}
          </Typography>
        )}
      </Box>
    </components.MultiValue>
  );
};

const CheckboxOption = (deleteItemBtnProps?: MultiDeleteItemBtnProps) => (props: any) => {
  const {
    isSelected,
    data: { customIcon, cryptoIcon, label, tag },
  } = props as { data: OptionType; isSelected?: boolean };

  return (
    <components.Option {...props}>
      <CustomCheckbox checked={isSelected} disabled={!!deleteItemBtnProps} sx={{ p: 0 }} />
      {cryptoIcon && <CryptoIcon icon={cryptoIcon.toLowerCase()} />}
      {customIcon && <CustomIcon icon={customIcon.toLowerCase()} />}
      <span>{label}</span>
      {tag && (
        <Typography color="white.opacity80" fontSize={12}>
          {tag}
        </Typography>
      )}
      {deleteItemBtnProps && (
        <IconButton
          sx={{ cursor: "pointer", ml: "auto" }}
          onClick={(e) => {
            e.stopPropagation();
            // eslint-disable-next-line
            deleteItemBtnProps.onClick(props.data as OptionType);
          }}
        >
          <CustomIcon icon="remove" />
        </IconButton>
      )}
    </components.Option>
  );
};

const MultiMenuComponentBtn = (props: MultiMenuBtnProps) => (
  <Box
    onClick={props.onClick}
    px={2}
    py={1.5}
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      cursor: "pointer",
      backgroundColor: "inputs.main",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }}
  >
    {props.text}
  </Box>
);

const CustomMultiMenu = (btnProps?: MultiMenuBtnProps) => (props: any) => {
  // eslint-disable-next-line
  if (props.disabled) return null;

  return (
    // eslint-disable-next-line
    <components.Menu {...props}>
      {
        // eslint-disable-next-line
        props.children
      }
      {btnProps && <MultiMenuComponentBtn {...btnProps} />}
    </components.Menu>
  );
};

export const customReactSelectComponents: SelectComponentsConfig<OptionType, boolean, GroupBase<OptionType>> = {
  IndicatorSeparator: null,
  DropdownIndicator,
  Control,
  Option,
  SingleValue,
};

export const customMultiReactSelectComponents = ({
  btnProps,
  deleteItemBtnProps,
}: MultiSelectBtnProps): MultiSelectComponentsType => ({
  IndicatorSeparator: null,
  DropdownIndicator,
  Control,
  SingleValue,
  Option: CheckboxOption(deleteItemBtnProps),
  Menu: CustomMultiMenu(btnProps),
  MultiValue,
});
