import { RouteParams } from "./route-params";

const RiskMonitoringHome = `/${RouteParams.ClientName}/risk`;

const Chains = `${RiskMonitoringHome}/chains`;
const ChainDetails = `${Chains}/${RouteParams.Chain}`;

const ChainDetailsOverview = `${Chains}/${RouteParams.Chain}/overview`;
const ChainDetailsListedAssets = `${Chains}/${RouteParams.Chain}/listed-assets`;
const ChainDetailsRecommendations = `${Chains}/${RouteParams.Chain}/recommendations`;
const ChainDetailsEMode = `${Chains}/${RouteParams.Chain}/e-mode`;

const ChainAssetOverview = `${ChainDetailsListedAssets}/${RouteParams.Asset}`;
const ChainAssetRecommendations = `${ChainDetailsRecommendations}/${RouteParams.Asset}`;
const EModeDetails = `${ChainDetailsEMode}/${RouteParams.EModeId}`;

const Assets = `${RiskMonitoringHome}/assets`;
const AssetDetails = `${Assets}/${RouteParams.Asset}`;

const Wallets = `${RiskMonitoringHome}/wallets`;
const WalletDetails = `${Wallets}/${RouteParams.Address}`;

const Markets = `${RiskMonitoringHome}/markets`;
const MarketDetails = `${Markets}/${RouteParams.MarketId}`;

const MarketDetailsOverview = `${Markets}/${RouteParams.MarketId}/overview`;
const MarketDetailsListedAssets = `${Markets}/${RouteParams.MarketId}/listed-assets`;
const MarketAssetOverview = `${MarketDetailsListedAssets}/${RouteParams.Asset}`;
const MarketAssetPools = `${MarketDetailsListedAssets}/${RouteParams.Asset}/pools`;

const MarketEModes = `${Markets}/${RouteParams.MarketId}/e-mode`;
const MarketEmodeDetails = `${MarketEModes}/${RouteParams.EModeId}`;
const MarketAmm = `${Markets}/${RouteParams.MarketId}/amm`;

const Pools = `${RiskMonitoringHome}/pools`;
const Pool = `${Pools}/${RouteParams.PoolId}`;

export const UnderReviewPathSegment = "/under-review";

const MarketDetailsRecommendations = (isUnderReview?: boolean) =>
  `${Markets}/${RouteParams.MarketId}/recommendations${isUnderReview ? UnderReviewPathSegment : ""}`;
const MarketAssetRecommendations = (isUnderReview?: boolean) =>
  `${MarketDetailsRecommendations(isUnderReview)}/${RouteParams.Asset}`;
const ExperimentResultsCurrent = (isUnderReview?: boolean) =>
  `${MarketAssetRecommendations(isUnderReview)}/experiment/current`;
const ExperimentResultsRecommended = (isUnderReview?: boolean) =>
  `${MarketAssetRecommendations(isUnderReview)}/experiment/recommended`;
const SimulationResultsCurrent = (isUnderReview?: boolean) =>
  `${ExperimentResultsCurrent(isUnderReview)}/simulation-results/${RouteParams.SimulationResultId}`;
const SimulationResultsRecommended = (isUnderReview?: boolean) =>
  `${ExperimentResultsRecommended(isUnderReview)}/simulation-results/${RouteParams.SimulationResultId}`;

// Perpetuals
const MarketPools = `${RiskMonitoringHome}/markets/${RouteParams.Market}/pools`;
const Positions = `${RiskMonitoringHome}/positions`;
const PositionDetails = `${Positions}/${RouteParams.PositionId}`;
const Accounts = `${RiskMonitoringHome}/accounts`;
const AccountPositions = `${Accounts}/${RouteParams.Address}`;
const AccountPositionsOverview = `${Accounts}/${RouteParams.Address}/positions`;
const AccountPositionsAnalysis = `${Accounts}/${RouteParams.Address}/analysis`;
const SubAccountPositions = `${AccountPositions}/subAccount/${RouteParams.SubAccount}`;
const SubAccountPositionsOverview = `${AccountPositions}/subAccount/${RouteParams.SubAccount}/overview`;
const MarketDetailsPool = `${Markets}/${RouteParams.MarketId}/pool`;
const Leaderboard = `${RiskMonitoringHome}/leaderboard`;
const GlvDetails = `${Markets}/glv/${RouteParams.Address}`;

// Chain Analytics
const EcosystemPerformance = `${RiskMonitoringHome}/ecosystem-performance`;
const UserEngagement = `${RiskMonitoringHome}/user-engagement`;

// Leverage
const CreditAccounts = `${RiskMonitoringHome}/credit-accounts`;
const CreditAccountDetails = `${CreditAccounts}/${RouteParams.CreditAccountId}`;
const CreditManager = `${Pool}/creditManagers/${RouteParams.CreditManagerId}`;

// Staking
const Withdrawals = `${RiskMonitoringHome}/withdrawals`;
const Avs = `${RiskMonitoringHome}/avs`;
const NodeOperators = `${RiskMonitoringHome}/node-operators`;
const AvsDetails = `${Avs}/${RouteParams.AvsId}`;
const NodeOperatorDetails = `${NodeOperators}/${RouteParams.NodeOperatorId}`;

// DefiApp
const FractionLeaderboard = `${RiskMonitoringHome}/fraction-leaderboard`;
const DegenLeaderboard = `${RiskMonitoringHome}/degen-leaderboard`;
const FactionDetails = `${FractionLeaderboard}/${RouteParams.FactionId}`;
const DegenDetails = `${DegenLeaderboard}/${RouteParams.DegenId}`;

export const RiskMonitoringRoutes = {
  Home: RiskMonitoringHome,
  Overview: `${RiskMonitoringHome}/overview`,
  Assets,
  AssetDetails,
  Wallets,
  WalletDetails,
  Tokens: `${RiskMonitoringHome}/tokens`,
  Liquidations: `${RiskMonitoringHome}/liquidations`,
  SoftLiquidations: `${RiskMonitoringHome}/soft-liquidations`,
  RiskExplorer: `${RiskMonitoringHome}/risk-explorer`,
  Reserves: `${RiskMonitoringHome}/reserves`,
  Alerts: `${RiskMonitoringHome}/alerts`,
  JlpPool: `${RiskMonitoringHome}/jlp-pool`,
  StablecoinHome: (slug: string) => `${RiskMonitoringHome}/${slug}`,
  StablecoinOverview: (slug: string) => `${RiskMonitoringHome}/${slug}/overview`,
  StablecoinRisk: (slug: string) => `${RiskMonitoringHome}/${slug}/risk`,
  StablecoinPools: (slug: string) => `${RiskMonitoringHome}/${slug}/pools`,
  Risk: `${RiskMonitoringHome}/risk`,
  Chains,
  ChainDetails,
  ChainDetailsOverview,
  ChainDetailsListedAssets,
  ChainDetailsRecommendations,
  ChainDetailsEMode,
  EModeDetails,
  ChainAssetOverview,
  ChainAssetRecommendations,

  // Markets
  Markets,
  MarketDetails,
  MarketDetailsOverview,
  MarketDetailsListedAssets,
  MarketAssetOverview,
  MarketAssetPools,
  MarketEModes,
  MarketEmodeDetails,
  MarketAmm,

  // Recommendations
  MarketDetailsRecommendations,
  MarketAssetRecommendations,
  ExperimentResultsCurrent,
  ExperimentResultsRecommended,
  SimulationResultsCurrent,
  SimulationResultsRecommended,

  // Pools
  Pools,
  Pool,

  // Perpetuals
  MarketPools,
  Positions,
  PositionDetails,
  Accounts,
  AccountPositions,
  AccountPositionsOverview,
  AccountPositionsAnalysis,
  SubAccountPositions,
  SubAccountPositionsOverview,
  MarketDetailsPool,
  Leaderboard,
  GlvDetails,

  // Chain Analytics
  EcosystemPerformance,
  UserEngagement,

  // Leverage
  CreditAccounts,
  CreditAccountDetails,
  CreditManager,

  // Staking
  Withdrawals,
  Avs,
  AvsDetails,
  NodeOperators,
  NodeOperatorDetails,

  // DefiApp
  FractionLeaderboard,
  DegenLeaderboard,
  FactionDetails,
  DegenDetails,
};
