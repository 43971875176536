export enum DefiAppApiEndpoint {
  Factions = "factions",
  FactionDegens = "fractions/{id}/degens",
  Degens = "degens",
  DegenSwapPoints = "degens/{id}/swap-points",
  DegenReferralPoints = "degens/{id}/referral-points",
}

export type FactionResponse = {
  ranking: number;
  factionId: string;
  factionName: string;
  image: string | null;
  ownerName: string | null;
  ownerWebsite: string | null;
  ownerXLink: string | null;
  createdAtTimestamp: number;
  numberOfDegens: number;
  totalPoints: string;
  bonusPercentage: number;
};

export type FractionDegenResponse = {
  ranking: number;
  userId: string;
  username: string;
  joinedAtTimestamp: number;
  leftAtTimestamp?: number;
  totalPoints: string;
};

export type DegenResponse = {
  ranking: number;
  userId: string;
  username: string;
  faction: {
    factionId: string;
    factionName: string;
    factionImage: string;
  };
  totalPoints: string;
};

export type DegenSwapPointsResponse = {
  factionId: string;
  factionName: string;
  factionImage: string;
  degenFactionPoints: number;
};

export type DegenReferralPointsResponse = {
  faction: {
    factionId: string;
    factionName: string;
    factionImage: string;
  };
  referee: {
    userId: string;
    username: string;
    createdAt: number;
  };
  totalPoints: number;
};

export type IdInterpolation = {
  id: string;
};

type DefiAppApiResponseMap = {
  [DefiAppApiEndpoint.Factions]: FactionResponse[];
  [DefiAppApiEndpoint.FactionDegens]: FractionDegenResponse[];
  [DefiAppApiEndpoint.Degens]: DegenResponse[];
  [DefiAppApiEndpoint.DegenSwapPoints]: DegenSwapPointsResponse[];
  [DefiAppApiEndpoint.DegenReferralPoints]: DegenReferralPointsResponse[];
};

type DefiAppApiRequestUrlInterpolation = {
  [DefiAppApiEndpoint.Factions]: undefined;
  [DefiAppApiEndpoint.FactionDegens]: IdInterpolation;
  [DefiAppApiEndpoint.Degens]: undefined;
  [DefiAppApiEndpoint.DegenSwapPoints]: IdInterpolation;
  [DefiAppApiEndpoint.DegenReferralPoints]: IdInterpolation;
};

export type DefiAppApiResponse<T extends DefiAppApiEndpoint> = DefiAppApiResponseMap[T];
export type DefiAppApiUrlInterpolation<T extends DefiAppApiEndpoint> = DefiAppApiRequestUrlInterpolation[T];
