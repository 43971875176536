import { Filter } from "@frontend/ui";
import { useCallback, useEffect, useState } from "react";
import { SortChangeHandler } from "@frontend/ui/hooks/use-pagination";
import {
  MarketFragment,
  PositionFragment,
  SortOrder,
  SortPositionsBy,
  useMarketsQuery,
  usePositionsLazyQuery,
} from "../../generated";
import { getOptionsFilterValue, getPositionsFilters, getRangeFilterValue, getSideFilterValue } from "./utils";
import { useClientConfig } from "../../clients-config";

type Props = {
  open?: boolean;
  account?: string;
  subAccount?: number;
  market?: MarketFragment;
  sort?: SortPositionsBy;
  pageSize?: number;
};

export const usePositionsData = ({ open, account, subAccount, market, sort, pageSize = 10 }: Props = {}) => {
  const {
    positions: { showPositionKeyColumn, useBasePnl },
  } = useClientConfig();
  const [positions, setPositions] = useState<PositionFragment[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [query, setQuery] = useState({
    order: SortOrder.Descending,
    sort: sort || SortPositionsBy.BalanceUsd,
    skip: 0,
  });
  const [fetchPositions, { loading }] = usePositionsLazyQuery();
  const [search, setSearch] = useState("");

  useMarketsQuery({
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      const initialFilters = getPositionsFilters({
        assets: !market
          ? data.markets.map((m) => ({
              name: m.name,
              token: m.symbol,
              value: m.id,
            }))
          : [{ name: market.name, token: market.symbol, value: market.id }],
      });
      setFilters(initialFilters);
    },
  });

  useEffect(() => {
    void fetchPositions({
      variables: {
        input: {
          limit: 100,
          skip: query.skip,
          sort: query.sort,
          order: query.order,
          filter: {
            assetSymbol: !market ? getOptionsFilterValue(filters, SortPositionsBy.AssetSymbol) : [market.id],
            side: getSideFilterValue(filters),
            balanceUSD: getRangeFilterValue(filters, SortPositionsBy.BalanceUsd),
            leverage: getRangeFilterValue(filters, SortPositionsBy.Leverage),
            pnl: getRangeFilterValue(filters, SortPositionsBy.Pnl),
            realizedPnl: useBasePnl ? getRangeFilterValue(filters, SortPositionsBy.RealizedPnl) : null,
            unrealizedPnl: useBasePnl ? getRangeFilterValue(filters, SortPositionsBy.UnrealizedPnl) : null,
            search: search || null,
            open: open || false,
            account: account || null,
            subAccount: subAccount !== undefined ? subAccount : null,
          },
          searchByPositionId: showPositionKeyColumn ? true : null,
        },
      },
    }).then(({ data }) => {
      if (data?.positions) {
        setPositions((p) => (query.skip ? [...p, ...data.positions] : data.positions));
      }
    });
  }, [fetchPositions, account, subAccount, query, filters, open, search, market, showPositionKeyColumn, useBasePnl]);

  const onPageChange = useCallback(
    (page: number) => {
      if (positions && pageSize * page === positions.length) {
        setQuery((q) => ({ ...q, skip: positions.length }));
      }
    },
    [positions, pageSize],
  );
  const onFiltersChange = useCallback((updatedFilter: Filter[]) => {
    setFilters([...updatedFilter]);
    setQuery((q) => ({ ...q, skip: 0 }));
  }, []);
  const onSortChange: SortChangeHandler = useCallback((sortBy, orderBy) => {
    setQuery({
      skip: 0,
      sort: sortBy as SortPositionsBy,
      order: orderBy === 1 ? SortOrder.Ascending : SortOrder.Descending,
    });
  }, []);
  const onSearch = useCallback((text: string) => setSearch(text.toLowerCase()), []);

  return {
    positions,
    filters,
    loading,
    onPageChange,
    onFiltersChange,
    onSortChange,
    onSearch,
  };
};
