import { useParams } from "react-router-dom";
import { PropsWithChildren, ReactNode } from "react";
import { ChaosApolloProvider } from "../../utils/graphql";
import { FeatureFlagsProvider } from "../../utils/feature-flags";
import { PageTracker } from "../page-tracker";
import { groups } from "../protocol-switcher/protocol-groups";
import { usePageTitle } from "../../hooks";
import { RiskMonitoringPerpetuals } from "../../pages/ccar-perpetuals-page/routes";
import { RiskMonitoringLendingRoutes } from "../../pages/ccar-lending-page/routes/risk-monitoring";
import { ChainAnalyticsRoutes } from "../../pages/chain-analytics";
import { RiskMonitoringLeverageRoutes } from "../../pages/risk-monitoring-leverage";
import { RiskMonitoringStakingRoutes } from "../../pages/risk-monitoring-staking";
import { DefiAppLeaderboardsRoutes } from "../../pages/defiapp-leaderboard";

const WithProviders = ({ children }: PropsWithChildren<ReactNode>) => (
  <ChaosApolloProvider product="ccar-lending">
    <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
  </ChaosApolloProvider>
);

export const RiskMonitoringRoutes = PageTracker(() => {
  const { clientName } = useParams();
  const currentProtocolKey = Object.entries(groups).find(([k]) => clientName?.includes(k));
  const currentProtocol = currentProtocolKey && currentProtocolKey[1];

  const { subLabel, options } = currentProtocol || {};
  const pageTitleSuffix = subLabel || options?.find((opt) => opt.value === clientName)?.label || "";
  usePageTitle(currentProtocol ? `${currentProtocol.label} ${pageTitleSuffix}` : "Risk");

  if (currentProtocol?.type === "perp") {
    return <RiskMonitoringPerpetuals />;
  }

  if (currentProtocol?.type === "chain-analytics") {
    return <ChainAnalyticsRoutes />;
  }

  if (currentProtocol?.type === "leverage") {
    return <RiskMonitoringLeverageRoutes />;
  }

  if (currentProtocol?.type === "staking") {
    return <RiskMonitoringStakingRoutes />;
  }

  if (currentProtocol?.type === "defiapp") {
    return (
      <ChaosApolloProvider product="common">
        <FeatureFlagsProvider>
          <DefiAppLeaderboardsRoutes />
        </FeatureFlagsProvider>
      </ChaosApolloProvider>
    );
  }

  return (
    <WithProviders>
      <RiskMonitoringLendingRoutes />
    </WithProviders>
  );
});
