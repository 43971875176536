import { Grid } from "@frontend/ui";
import { formatDuration } from "@frontend/ui/utils/formatters";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { FactionResponse, FractionDegenResponse } from "../../../api/types";

type Props = {
  faction?: FactionResponse;
  factionDegens?: FractionDegenResponse[];
};

const Cards = ({ faction, factionDegens }: Props) => {
  const cards: (ValueCardProps & { md?: number })[] = [
    {
      title: "Rank",
      value: faction?.ranking,
      icon: "view-list",
    },
    {
      title: "Age",
      value: faction
        ? formatDuration({
            milliseconds: new Date().getTime() - faction.createdAtTimestamp,
            compactHours: 24,
          })
        : undefined,
      icon: "clock",
    },
    {
      title: "Number of Members",
      value: faction?.numberOfDegens,
      icon: "users",
    },
    {
      title: "Points",
      value: faction ? Number(faction.totalPoints) : undefined,
      icon: "bubble-race",
      md: 6,
    },
    {
      title: "Pledged Degen Share",
      value:
        factionDegens !== undefined ? factionDegens.reduce((total, d) => total + Number(d.totalPoints), 0) : undefined,
      icon: "line-down-dots",
      md: 6,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={card.md ?? 4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
