import { MouseEventHandler, ReactNode } from "react";
import { Dictionary, Nullable } from "./utilities";
import { ChartType, ChartDataSource } from "./enums";

/**
 * A task is a generic document that tells the Cloud Function
 * how to run the backgorund code.
 */
export interface Task {
  // the state of the tasks, useful for debugging and/or querying.
  status: TaskStatus;
  // the name of worker function, which contains the business logic to execute.
  worker: string;
  // a map containing extra data for the worker function, like a userID argument for example.
  options: Dictionary<string>;
}

export enum TaskStatus {
  SCHEDULED = "scheduled",
  COMPLETE = "complete",
  ERROR = "error",
}

export type Roles = "admin";

/**
 * A user
 */
export interface User {
  uid: string;
  email: Nullable<string>;
  emailVerified: boolean;
  isAnonymous: boolean;
  refreshToken: Nullable<string>;
  registered: boolean;
  firstName: string;
  lastName: string;
  photo: Nullable<{
    url: string;
    documentId: string;
    fullPath: string;
  }>;
  role?: Roles;
}

export interface ConditionItem {
  leftValue: string;
  leftName: object | string;
  rightValue: string;
  rightName: object | string;
  operator: "==" | "<=" | ">=" | "!=" | ">" | "<" | "%" | undefined;
}

export interface ConditionRelation {
  leftCondition?: ConditionItem | ConditionRelation;
  rightCondition?: ConditionItem | ConditionRelation;
  connector?: "||" | "&&" | null;
}

export type ArgType = "string" | "number" | "string[]" | "number[]" | "ConfigurableParam" | "RandomParam";

export interface ScriptArg {
  type: ArgType;
  description?: string;
  value?: string | number | string[] | number[] | ConfigurableTypeParams | RandomTypeParams;
}

export type ScriptArgs = Record<string, ScriptArg>;

export interface Script {
  name: string;
  description?: string;
  thumbnail?: FileValue;
  code: string;
  args?: ScriptArgs;
  argsDefinition?: ScriptArgs;
  isClass?: boolean;
}
export interface ObserverScript extends Script {
  dataType?: ObserverDataType;
  chartType?: ObserverChartType;
  valueType?: ObserverValueType;
  valueLabel?: string;
}

export type ObserverDataType = "event" | "aggregated";
export const observerDataTypeOptions = [
  {
    label: "Per Block",
    value: "event",
  },
  {
    label: "Aggregated",
    value: "aggregated",
  },
];
export type ObserverChartType = "LINE" | "AREA";
export const observerChartTypeOptions = [
  {
    label: "Area",
    value: "AREA",
  },
  {
    label: "Line",
    value: "LINE",
  },
  {
    label: "Histogram",
    value: "HISTOGRAM",
  },
];
export type ObserverValueType = "decimal" | "currency" | "percent";
// V2  resources:

export interface ScriptResource {
  id: string;
  name: string;
  configName?: string;
  description?: string;
  args: Record<string, ScriptArg>;
  fullPath: string;
  instanceId: string;
  refId: string;
  displayOrder?: number;
  thumbnail?: FileValue;
  title?: string;
  type?: "setup" | "runtime";
  dataType?: ObserverDataType;
  chartType?: ObserverChartType;
  valueType?: ObserverValueType;
  currencyCode?: string;
  valueLabel?: string;
  isMetric?: boolean;
  isVisible?: boolean;
}

export type AssertionType = "OneTime" | "Invariant";

export interface ScriptResourceGroupInput {
  groupId: string;
  groupName: string;
  observerIds: string[];
  dataType?: ObserverDataType;
  chartType?: ObserverChartType;
  valueType?: ObserverValueType;
  valueLabel?: string;
}

export type ConfigurableTypeParams = {
  minValue: number;
  maxValue: number;
  interval: number;
};

export type RandomTypeParams = {
  minValue: number;
  maxValue: number;
};

export type CalculatedRandomArgData = {
  argName: string;
  argValue: number;
  scriptId: string;
};

export function isTypedArg(arg: ScriptArg) {
  return arg?.type === "RandomParam" || arg?.type === "ConfigurableParam";
}

export function isRandomTypeParams(arg: any): arg is RandomTypeParams {
  return (
    (arg as ConfigurableTypeParams)?.minValue !== undefined && (arg as ConfigurableTypeParams)?.maxValue !== undefined
  );
}

export function isConfigurableTypeParams(arg: any): arg is ConfigurableTypeParams {
  return (
    (arg as ConfigurableTypeParams)?.minValue !== undefined &&
    (arg as ConfigurableTypeParams)?.maxValue !== undefined &&
    (arg as ConfigurableTypeParams)?.interval !== undefined
  );
}

export type CodeEntity = "Agent" | "Assertion" | "Scenario" | "Observer";

// end of V2 resources

// ORGID_USERID

export interface Org {
  name: string;
  protocolWebsite?: string;
  protocolBlockchain?: string;
  invitationEmails: string[];
}

export interface OrgMembership {
  isAdmin: boolean;
}

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export interface FileValue {
  url: string;
  documentId: string;
  fullPath: string;
}

export interface ContractMethodVariable {
  name: string;
  type: string;
  internalType: string;
}

export interface ContractMethod {
  name: string;
  inputs: ContractMethodVariable[];
  outputs: ContractMethodVariable[];
  stateMutability: string;
  type: string;
}

export enum ContractStatus {
  verified = "verified",
  deployed = "deployed",
  uploaded = "uploaded",
}

export interface Contract {
  id?: string;
  contractName: string;
  address: string;
  bytecode: string;
  abi: ContractMethod[];
  status: keyof typeof ContractStatus;
  size: number;
  bucket: string;
  updated: string;
  fullPath: string;
  contentType: string;
  resourceName: string;
}

export type Permissions =
  | "scenarios"
  | "agents"
  | "ci"
  | "team_settings"
  | "deployments"
  | "simulations"
  | "oracles"
  | "assertions"
  | "calculator"
  | "assets"
  | "analytics"
  | "observers"
  | "trigger_simualation"
  | "recommendations";

export type FeatureFlags = "";

export interface Team {
  id: string;
  authKey?: string;
  name: string;
  protocolBlockchain: string;
  protocolWebsite: string;
  thumbnail?: FileValue;
  permissions: Permissions[];
  featureFlags?: FeatureFlags[];
}

export interface TeamMembership {
  id: string;
  isAdmin: boolean;
  teamId: string;
}

export interface Validator {
  condition: string;
  description: string;
  name: string;
  type: string;
}

export interface Duration {
  name: string;
  duration: number; // seconds
  blocksCount: number;
}

export interface BlockchainSimulation {
  id: string;
  name: string;
  lowercaseName: string;
  ownerId: string;
  agents: Partial<ScriptResource>[];
  assertions: Partial<ScriptResource>[];
  observers: Partial<ScriptResource>[];
  observerGroupsMetadata?: ObserverGroupMetadata[];
  blockNumber?: number;
  chain?: string;
  description?: string;
  iterations: number;
  iterTimeout?: number;
  anvilFork?: boolean;
  scenarios: {
    runtime: Partial<ScriptResource>[];
    setup: Partial<ScriptResource>[];
  };
  simulationType?: SimulationType;
}

export type ChartLegends = { left?: string; right?: string; bottom?: string };

export type ObserverGroupMetadata = {
  groupId: string;
  groupName: string;
  observerIds?: string[];
  observerInstanceIds?: string[];
  displayOrder?: number;
  secondaryAxisObserverIds?: string[];
  secondaryAxisInstanceIds?: string[];
  dataType?: ObserverDataType;
  chartType?: ObserverChartType;
  valueType?: ObserverValueType;
  currencyCode?: string;
  valueLabel?: string;
  legends?: ChartLegends;
  isVisible?: boolean;
};

export enum SimulationType {
  Standalone = "Standalone",
  ParameterConfiguration = "ParameterConfiguration",
}

export interface SimulationContract {
  abi: {
    inputs: { internalType: string; name: string; type: string }[];
    name: string;
    outputs: { internalType: string; name: string; type: string }[];
    stateMutability: string;
    type: string;
  }[];
  bytecode: string;
  contractName: string;
  isErc20: boolean;
  sourceCode: string;
  address: string;
  simulationResultId: string;
}

export interface SimulationBlock {
  id: string;
  gasLimit: number;
  gasUsed: number;
  baseFeePerGas: number;
  hash: string;
  number: number;
  simulationId: string;
  timestamp: number;
  transactionsCount: number;
}

export interface SimulationTransaction {
  id: string;
  hash: string;
  blockNumber: number;
  connectionKey: string;
  from: { address: string; name?: string };
  to?: { address: string; name?: string };
  gasLimit: number;
  gasUsed?: number;
  gasPrice: string;
  methodName?: string;
  methodId: string;
  simulationId: string;
  transactionIndex: number;
  type: number;
  value: string;
  timestamp: number;
  inputNames: string[];
  inputTypes?: string[];
  inputValues?: string[];
  status?: number;
  tokenTransfers?: {
    tokenAddress: string;
    from: string;
    to: string;
    amount: string;
    token?: string;
    formattedAmount?: string;
  }[];
}

export interface SimulationEvent {
  address: string;
  args: {
    indexed: boolean;
    name: string;
    type: string;
    value: string;
  }[];
  blockNumber: number;
  connectionKey: string;
  logIndex: number;
  name: string;
  signature: string;
  simulationResultId: string;
  topics: string[];
  transactionHash: string;
}

export type AggregationName = "average" | "last" | "max" | "min" | "sum";

export type AggregationStatistics = {
  variance: number;
  mean: number;
  confidence_interval: string;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  p99: number;
  min: number;
  max: number;
};

export type RepetitionsDataType = Record<string, ObservationAggregations>;

export type MetricStatistics = {
  id: string;
  aggregationStatistics: Record<AggregationName, AggregationStatistics>;
  confidenceIntervalOverTimePath: string;
  repetitionsDataPath: string;
};

export type PermutationArgInfo = {
  argName: string;
  argValue: string | number;
  scriptId: string;
};

export type PermutationInfo = {
  args: PermutationArgInfo[];
  id: string;
  resultIds?: string[];
};

export type ExprimentalStatistics = {
  metricStatistics: MetricStatistics[];
  permutationInfo?: PermutationInfo;
};

export type SimulationStatistics = {
  simulationId: string;
  simulationResultId: string;
  data: ExprimentalStatistics[];
  errorMessage?: string;
};

export interface BlockchainSimulationResult {
  id: string;
  state: string;
  simulationId: string;
  result: BlockchainSimulationRunResult;
  error?: string;
  meta?: BlockchainSimulationResultMeta;
  premutation?: SimulationPermutationInfo;
  permutation?: SimulationPermutationInfo;
  parentResultId?: string;
  dataProcessingResultId?: string;
  simulationSnapshot?: BlockchainSimulation;
  pythonSimId?: string;
}

export interface PythonSimulationResult {
  id: string;
  metadata: {
    debug_blob_urls: {
      liquidation_calls_blob_url: string;
      trajectory_blob_url: string;
    };
  };
}

export interface BlockchainSimulationRunResult {
  assertions?: BlockchainSimulationAssertionResult[];
  observations?: string[];
  observationsRef?: ObservationRef[];
  observationsInfo?: SimulationPermutationObservationResult[];
  permutations?: SimulationPermutationInfo[];
  observationsMetaData: Record<string, ObservationAggregationResult>;
}

export enum SimulationProgressState {
  SimulationFinished = "simulation finished",
}

export interface ObservationRef {
  id: string;
  name: string;
  path: string;
  format?: "csv";
  publicUrl?: string;
}

export interface ObservationAggregationResult {
  id: string;
  aggregations: ObservationAggregations;
}

export interface SimulationPermutationObservationResult {
  id: string;
  name: string;
  permutationId: string;
  aggregations: ObservationAggregations;
}

export interface SimulationPermutationResult {
  id: string;
  parentResultID: string;
  args: SimulationPermutationArgInfo[];
  observationInfos: SimulationPermutationObservationResult[];
  metricStatistics?: MetricStatistics[];
}

export type ObservationAggregations = {
  first: number;
  last: number;
  average: number;
  sum: number;
  max: number;
  min: number;
};

export enum AggregationMethods {
  Last = "Last",
  Average = "Average",
  Sum = "Sum",
  Max = "Max",
  Min = "Min",
}

export interface SimulationPermutationInfo {
  args: SimulationPermutationArgInfo[];
  id: string;
  resultIds: string[];
}

export interface SimulationPermutationArgInfo {
  argName: string;
  argValue: number;
  scriptId: string;
  isAddOn?: boolean;
}

export interface BlockchainSimulationAssertionResult {
  id: string;
  name: string;
  description?: string;
  errorMessage?: string;
  success?: boolean;
  failureBlock?: number;
  failureIteration?: number;
  refId?: string;
  type: AssertionType;
}

export interface BlockchainSimulationResultMeta {
  startBlock: number;
  endBlock: number;
  currentIteration?: number;
  iterations?: number;
  remotePoolURL?: string;
  externalRemotePoolURL?: string;
  connectionKey?: string;
  calculatedRandomArgData?: BlockchainSimulationCalculatedRandomArgData[];
  observersStartBlock?: number;
}

export interface BlockchainSimulationCalculatedRandomArgData {
  argName: string;
  argValue: number;
  scriptId: string;
}

export interface AddressOverviewCardInterface {
  title: string;
  address: string;
}

export interface DashboardPayload {
  selected?: boolean;
  id: string;
  name: string;
  content: Array<DashboardChartInterface>;
  isShared?: boolean;
  description?: string;
  icon?: string;
  image?: string;
  network?: string;
  shouldRender?: boolean;
}

export interface DashboardChartInterface {
  data: Array<ChartData>;
  shouldRender: boolean;
}

export interface ChartData {
  chartId: string;
  queryData?: any;
  chartTitle?: { key: string; value: string };
  chartSubtitle?: { key: string; value: string };
  chartDetail?: { key: string; value: string };
  chartDelta?: { key: string; value: string };
  chartType: ChartType;
  chartDataSource: ChartDataSource;
  chartDataSourceUrl?: string;
  chartColor?: string | undefined;
  chartMetaData?: Record<string, any>;
  dataPayload?: any;
  shouldRender: boolean;
  chartDescription?: string;
  columnsToFill?: number;
  labelPrefix?: string;
  labelSuffix?: string;
  hideChartLegends?: boolean;
  hideXAxisLabels?: boolean;
  hidePoints?: boolean;
}

export interface ResultMeta {
  connectionKey: string;
  isLocal: boolean;
  remotePoolURL: string;
  simulationID: string;
  startBlock?: number;
  endBlock?: number;
}

export interface Asset {
  id: string;
  name: string;
  symbol: string;
  teamName: string;
  tags: ("Listed" | "Pending" | "Proposed")[];
  geckoId?: string;
  messariId?: string;
  address?: string;
  proposalId?: string;
  onChainProposalId?: string;
}

export type RenderType =
  | "JSON"
  | "TEXT"
  | "LINK"
  | "LABELS"
  | "PIE_CHART"
  | "CHIP"
  | "CUSTOM"
  | "PROGRESS"
  | "BUTTON"
  | "ICONS"
  | "FILTER"
  | "RECOMMENDATION";

export type ExtraData = {
  title?: string;
  data?: Record<string, string>[] | (() => Promise<Record<string, string>[]>);
  icon?: string;
  symbol?: string;
};

export type CellStatusType = "OK" | "WARNING" | "ERROR" | "GOOD" | "PROGRESS" | "RECOMMENDED" | "INFO";

export type RenderData = {
  renderType: RenderType;
  text: string;
  searchTerm?: string;
  exportText?: string;
  value?: number | null;
  isValueExcluded?: boolean;
  customIcon?: string;
  cryptoIcon?: string;
  token1?: string;
  token2?: string;
  extraData?: ExtraData;
  status?: CellStatusType;
  tooltipText?: string;
  textColor?: string;
  chipColor?: string;
  chipIcon?: string | ((value: string) => string);
  component?: ReactNode;
  progressValue?: number;
  width?: string | number;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  copyText?: string;
  icons?: string[];
  isMarked?: boolean;
  previousValue?: number;
  previousText?: string;
  filterByValue?: string | number;
  trend?: "up" | "down";
  identicon?: string;
  textSuffix?: string;
  valueToIcon?: Record<string, string>;
  textPosition?: "top" | "right" | "bottom" | "left";
  disableWrappedSymbol?: boolean;
  cryptoCurrency?: string;
  imgSrc?: string;
};

export type RowData = {
  renderData: RenderData[];
  id: number;
};

interface BaseHeader {
  renderType: "TEXT";
  text: string | number;
  prefix?: string;
  suffix?: string;
  width?: string;
  minWidth?: number;
  tooltip?: string;
  field?: string;
  sortOpposite?: boolean;
  nonSortable?: boolean;
  textHidden?: boolean;
  isAutocomplete?: boolean;
  icon?: string;
  isDate?: boolean;
  isDuration?: boolean;
}

export interface StatusHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "STATUS";
}

export interface RangeHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "RANGE";
  floor: number;
  ceiling: number;
}

export interface CurrencyHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "CURRENCY";
  unit: string;
}

export interface DeltaHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "DELTA";
}

export interface EmptyHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "EMPTY";
}

export interface FilterHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "FILTER";
}

export interface IconsHeader extends Omit<BaseHeader, "renderType"> {
  renderType: "ICONS";
}

export type Header =
  | StatusHeader
  | RangeHeader
  | CurrencyHeader
  | BaseHeader
  | DeltaHeader
  | EmptyHeader
  | FilterHeader
  | IconsHeader;

export type FundingRate = {
  market: string;
  price: string;
  effectiveAt: string;
  oneHourRate: string;
  eightHourRate: string;
  twentyFourHourRate: string;
  sevenDayRate: string;
  oneMonthRate: string;
  threeMonthsRate: string;
  oneYearRate: string;
};

export type CandleData = {
  baseTokenVolume: string;
  close: string;
  high: string;
  low: string;
  market: string;
  open: string;
  resolution: string;
  startedAt: string;
  startingOpenInterest: string;
  trades: string;
  updatedAt: string;
  usdVolume: string;
};

export type DyDxPerptualMarkets =
  | "BTC-USD"
  | "ETH-USD"
  | "LINK-USD"
  | "AAVE-USD"
  | "UNI-USD"
  | "SUSHI-USD"
  | "SOL-USD"
  | "YFI-USD"
  | "1INCH-USD"
  | "AVAX-USD"
  | "SNX-USD"
  | "CRV-USD"
  | "UMA-USD"
  | "DOT-USD"
  | "DOGE-USD"
  | "MATIC-USD"
  | "MKR-USD"
  | "FIL-USD"
  | "ADA-USD"
  | "ATOM-USD"
  | "COMP-USD"
  | "BCH-USD"
  | "LTC-USD"
  | "EOS-USD"
  | "ALGO-USD"
  | "ZRX-USD"
  | "XMR-USD"
  | "ZEC-USD"
  | "ENJ-USD";

export type Axis = {
  label?: string;
  currency?: string;
  type?: "block" | "date" | "datetime" | "number";
  highlight?: {
    value: number;
    text: string;
  };
  angle?: number;
};

export type TokenPrice = {
  id: string;
  name: string;
  image: string;
  price: number;
};

export type TokenDetails = TokenPrice & Asset;

export type AssetTableRow = {
  id: string;
  name: string;
  riskScore: number;
  marketCap: string;
  demandScore: number;
  volume: string;
  revenue: number;
};

export type AssetTableRowData = AssetTableRow & Asset;

type LiquidityPool = {
  source: "uniswap" | "sushi";
  address: string;
  token0: string;
  token1: string;
  liquidity0: string;
  liquidity1: string;
  liquidityUSD: string;
  txCount: string;
  dailyVolumeUSD: string;
};

export type Liquidity = {
  totalLiquidity: string;
  liquidityChangePercentage24h: number;
  liquidityChangePercentage7d: number;
  dailyVolume: string;
  dailyVolumeChangePercentage24h: number;
  dailyVolumeChangePercentage7d: number;
  liquidityData: [number, number][];
  tradingVolumeData: [number, number][];
  topPools: LiquidityPool[];
};

type NativeTokenOnChainData = {
  type: "native";
  txVolumeLatest?: string;
  txVolumeChangePercentage24h?: number;
  txVolumeChangePercentage7d?: number;
  txVolume?: [number, number][];
};

export type TokenHolder = {
  address: string;
  balance: number;
  value: number;
  share: number;
  addressLabel?: string | null;
};

type ERC20TokenOnChainData = {
  type: "erc20";
  transfersCount?: string;
  transfersCountChangePercentage24h?: number;
  transfersCountChangePercentage7d?: number;
  transfers?: [number, number][];
  topHolders: {
    headers: Header[];
    data: RenderData[][];
  };
  top100Holders?: TokenHolder[];
  holderAssetDistribution: Record<"1-10" | "11-50" | "51-100" | ">100", number>;
  totalHolders: string;
  halfTotalSupplyHolders: string;
};

type TokenOnChainData = {
  txCount?: string;
  txCountChangePercentage24h?: number;
  txCountChangePercentage7d?: number;
  activeUsersCount?: string;
  activeUsersCountChangePercentage24h?: number;
  activeUsersCountChangePercentage7d?: number;
  transactions?: [number, number][];
  activeUsers?: [number, number][];
  totalUsers?: [number, number][];
  newUsers?: [number, number][];
} & (NativeTokenOnChainData | ERC20TokenOnChainData);

type TokenOffChainData = {
  topExchanges: {
    headers: Header[];
    data: RenderData[][];
  };
  topdDerivativesExchanges?: {
    headers: Header[];
    data: RenderData[][];
  };
};

type Vote = {
  id: string;
  voter: string;
  created: number;
  proposal: {
    id: string;
  };
  choice: number;
  space: {
    id: number;
  };
  vp: number;
};

export type Proposal = {
  id: string;
  title: string;
  body: string;
  choices: string[];
  start: number;
  end: number;
  snapshot?: string;
  state: string;
  author?: string;
  space?: {
    id: string;
    name: string;
  };
  scores: number[];
  scores_total: number;
  link: string;
  votes?: Vote[];
};

export type AuditItem = { title: string; date: number; details: string };

export type AssetInfo = {
  id: string;
  symbol: string;
  name: string;
  description?: string;
  marketData: {
    volume: string;
    volumeChangeValue24h: string;
    volumeChangePercentage24h: number;
    volumeChangeValue7d: string;
    volumeChangePercentage7d: number;
    currentPrice: string;
    marketCap: string;
    priceChangeValue24h: string;
    priceChangePercentage24h: number;
    priceChangeValue7d: string;
    priceChangePercentage7d: number;
    marketCapChangeValue24h: string;
    marketCapChangePercentage24h: number;
    marketCapChangeValue7d: string;
    marketCapChangePercentage7d: number;
    priceHigh30d: string;
    priceLow30d: string;
    marketCapHigh30d: string;
    marketCapLow30d: string;
  };
  links: {
    homepage?: string;
    twitter?: string;
    github?: string;
  };
  price30d: [number, number][];
  marketCap30d: [number, number][];
  tradingVolume30d: [number, number][];
  liquidity?: Liquidity;
  onChainData: TokenOnChainData;
  offChainData?: TokenOffChainData;
  social?: {
    sentimentPositivePercentage: number;
    sentimentNegativePercentage: number;
    twitterFollowers: [number, number][];
    twitterMentions: [number, number][];
    redditMentions: [number, number][];
    telegramMentions: [number, number][];
    socialKeywords: { word: string; score: number }[];
  };
  risk?: {
    socialSentiment: number;
    safetyAssessment: number;
    marketVolatility: number;
    total: number;
  };
  demand?: {
    demandVolumeScore: number;
    priceChangeScore: number;
    demandScore: number;
  };
  revenue: number;
  audits: AuditItem[];
  proposal?: Proposal;
  onChainProposal?: Proposal;
  tags: string[];
  tick?: {
    optimalTick?: number | string;
    currentTick?: number | string;
  };
  coinmarketcapId: number;
};

export type ResponseWithCount<T> = {
  totalCount: number;
  data: T[];
};

export type DataWrap<T> = { data: T };

export type ToolItemProps = {
  id: string;
  icon: string;
  title: string;
  link: string;
  externalLink?: boolean;
  subtitle?: string;
  data?: any[];
  assets?: Omit<Asset, "team" | "teamName">[];
};

export type EconnomicRecommendationTrigger = {
  id: string;
  assets: string[];
  chain_name: string;
  created: string;
  models: string[];
  protocol: string;
  status: "simulation pending" | "simulation finished";
};

export type EconnomicRecommendationPermutations = {
  liquidationBonusDelta: number;
  liquidationThresholdDelta: number;
  tvl: number;
};

type EconnomicRecommendationMetrics = {
  confidence_interval: string;
  max: number;
  mean: number;
  min: number;
  p25: number;
  p50: number;
  p75: number;
  p95: number;
  p99: number;
  variance: number;
};

export type EconnomicRecommendationParams = {
  metadata: {
    assets: string[];
    chain: string;
    model: string;
    protocol: string;
    start_time: string;
    status: string;
  };
  result: {
    asset_name: string;
    permutations: {
      metrics: {
        badDebt: EconnomicRecommendationMetrics;
        collateralAtRisk: EconnomicRecommendationMetrics;
        numberOfLiquidations: EconnomicRecommendationMetrics;
        totalValueLiquidated: EconnomicRecommendationMetrics;
      };
      parameters: {
        name: string;
        value: number;
      }[];
      rules: {
        id: string;
        messesage: string;
        result: boolean;
        ruleName: string;
      };
    }[];
    recommendations: {
      name: string;
      value: number;
      recommended: number;
    }[];
    result_id: string;
    status: string;
  }[];
};
